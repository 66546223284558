import { getAWSCognitorSession } from "../awsCognitor/awsCognitor";

export const getLastUpdated = (state) => state.majalisAndOffices.lastUpdated;
export const getLastFetch = (state) => state.majalisAndOffices.lastFetch;
export const getOfficesAndEvents = (state) => state.majalisAndOffices.officesAndEvents;
export const getOffices = (state) => state.majalisAndOffices.offices;
export const getMajalis = (state) => state.majalisAndOffices.majalis;
export const getHalaqat = (state) => state.majalisAndOffices.halaqat;
export const getOfficeBearers = (state) => state.majalisAndOffices.officeBearers;
export const getLocalAmlaMembers = (state) => state.majalisAndOffices.localAmlaMembers;

export const accessLocalAmlaNamazReports = (state) => state.recentReports.allLocalAmlaNamazReports;
export const accessMajlisNamazReports = (state) => state.recentReports.allMajlisNamazReports;
export const accessIndividualNamazReports = (state) => state.recentReports.allIndividualNamazReports;
export const accessActivityReports = (state) => state.recentReports.activityReports;
export const accessAllActivityReports = (state) => state.recentReports.allActivityReports;
export const accessOwnNamazReports = (state) => state.recentReports.ownNamazReports;
export const accessOwnMuhtamimReports = (state) => state.recentReports.ownMuhtamimReports;
export const accessOwnDoorToDoorReports = (state) => state.recentReports.ownDoorToDoorReports;
export const accessDoorToDoorSummary = (state) => state.recentReports.doorToDoorSummary;
export const accessAllMuhtamimReports = (state) => state.recentReports.allMuhtamimReports;

export const getUserSession = getAWSCognitorSession;

export const accessIjtemaMajlisBreakdown = (state) => state.ijtema.majlisBreakdown;
export const accessIjtemaIndividualBreakdown = (state) => state.ijtema.individualBreakdown;

export const accessIjtemaMajlisBreakdown2023 = (state) => state.ijtema.majlisBreakdown2023;
export const accessIjtemaIndividualBreakdown2023 = (state) => state.ijtema.individualBreakdown2023;

export const accessSpiritualReformReports = (state) => state.recentReports.ownSpiritualReformReports;