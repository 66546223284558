import * as actionTypes from './actionTypes'

export const updateRequiredLists = () => ({
  payload: {},
  type: actionTypes.UPDATE_REQUIRED_LISTS,
})

export const updateRequiredListsSuccess = (fullResponse) => ({
  payload: {
    ...fullResponse,
  },
  type: actionTypes.UPDATE_REQUIRED_LISTS_SUCCESS,
})

export const sendActivityReport = (req) => ({
  payload: { ...req },
  type: actionTypes.SEND_ACTIVITY_REPORT,
})

export const sendMuhtamimReport = (req) => ({
  payload: { ...req },
  type: actionTypes.SEND_MUHTAMIM_REPORT,
})

export const sendIndividualNamazReport = (req) => ({
  payload: { ...req },
  type: actionTypes.SEND_INDIVIDUAL_NAMAZ_REPORT,
})

export const sendLocalAmlaNamazReport = (req) => ({
  payload: { ...req },
  type: actionTypes.SEND_LOCAL_AMLA_NAMAZ_REPORT,
})

export const sendMajlisNamazReport = (req) => ({
  payload: { ...req },
  type: actionTypes.SEND_MAJLIS_NAMAZ_REPORT,
})

export const sendDoorToDoorReport = (req) => ({
  payload: { ...req },
  type: actionTypes.SEND_DOOR_TO_DOOR_REPORT,
})

export const sendSpiritualReformReport = (req) => ({
  payload: { ...req },
  type: actionTypes.SEND_SPIRITUAL_REFORM_REPORT,
})

export const getActivityReports = ({ monthYear }) => ({
  payload: { monthYear },
  type: actionTypes.GET_ACTIVITY_REPORTS,
})

export const getActivityReportsSuccess = ({ monthYear, result }) => ({
  payload: { monthYear, result },
  type: actionTypes.GET_ACTIVITY_REPORTS_SUCCESS,
})

export const getAllActivityReports = ({ monthYear }) => ({
  payload: { monthYear },
  type: actionTypes.GET_ALL_ACTIVITY_REPORTS,
})

export const getAllActivityReportsSuccess = ({ monthYear, result }) => ({
  payload: { monthYear, result },
  type: actionTypes.GET_ALL_ACTIVITY_REPORTS_SUCCESS,
})

export const deleteActivityReport = ({ id, index, monthYear }) => ({
  payload: { id, index, monthYear },
  type: actionTypes.DELETE_ACTIVITY_REPORT,
})

export const deleteActivityReportSuccess = ({ id, index, monthYear }) => ({
  payload: { id, index, monthYear },
  type: actionTypes.DELETE_ACTIVITY_REPORT_SUCCESS,
})

export const getOwnNamazReports = ({ monthYear }) => ({
  payload: { monthYear },
  type: actionTypes.GET_OWN_NAMAZ_REPORTS,
})

export const getOwnNamazReportsSuccess = ({ monthYear, result }) => ({
  payload: { monthYear, result },
  type: actionTypes.GET_OWN_NAMAZ_REPORTS_SUCCESS,
})

export const deleteIndividualNamazReport = ({ id, index, monthYear }) => ({
  payload: { id, index, monthYear, source: 'individualNamazReports' },
  type: actionTypes.DELETE_NAMAZ_REPORT,
})

export const deleteLocalAmlaNamazReport = ({ id, index, monthYear }) => ({
  payload: { id, index, monthYear, source: 'localAmlaNamazReports' },
  type: actionTypes.DELETE_NAMAZ_REPORT,
})

export const deleteMajlisNamazReport = ({ id, index, monthYear }) => ({
  payload: { id, index, monthYear, source: 'majlisNamazReports' },
  type: actionTypes.DELETE_NAMAZ_REPORT,
})

export const deleteNamazReportSuccess = ({ id, index, monthYear, source }) => ({
  payload: { id, index, monthYear, source },
  type: actionTypes.DELETE_NAMAZ_REPORT_SUCCESS,
})

export const getOwnMuhtamimReports = ({ monthYear }) => ({
  payload: { monthYear },
  type: actionTypes.GET_OWN_MUHTAMIM_REPORTS,
})

export const getOwnMuhtamimReportsSuccess = ({ monthYear, result }) => ({
  payload: { monthYear, result },
  type: actionTypes.GET_OWN_MUHTAMIM_REPORTS_SUCCESS,
})

export const deleteMuhtamimReport = ({ id, index, monthYear }) => ({
  payload: { id, index, monthYear },
  type: actionTypes.DELETE_MUHTAMIM_REPORT,
})

export const deleteMuhtamimReportSuccess = ({ id, index, monthYear }) => ({
  payload: { id, index, monthYear },
  type: actionTypes.DELETE_MUHTAMIM_REPORT_SUCCESS,
})

export const getOwnDoorToDoorReports = ({ monthYear }) => ({
  payload: { monthYear },
  type: actionTypes.GET_OWN_DOOR_TO_DOOR_REPORTS,
})

export const getOwnDoorToDoorReportsSuccess = ({ monthYear, result }) => ({
  payload: { monthYear, result },
  type: actionTypes.GET_OWN_DOOR_TO_DOOR_REPORTS_SUCCESS,
})

export const deleteDoorToDoorReport = ({ id, index, monthYear }) => ({
  payload: { id, index, monthYear },
  type: actionTypes.DELETE_DOOR_TO_DOOR_REPORT,
})

export const deleteDoorToDoorReportSuccess = ({ id, index, monthYear }) => ({
  payload: { id, index, monthYear },
  type: actionTypes.DELETE_DOOR_TO_DOOR_REPORT_SUCCESS,
})

export const getDoorToDoorSummary = ({ majlis }) => ({
  payload: { majlis },
  type: actionTypes.GET_DOOR_TO_DOOR_SUMMARY,
})

export const getDoorToDoorSummarySuccess = ({ majlis, result }) => ({
  payload: { majlis, result },
  type: actionTypes.GET_DOOR_TO_DOOR_SUMMARY_SUCCESS,
})

export const getDoorToDoorReports = ({ monthYear, majlis }) => ({
  payload: { monthYear, majlis },
  type: actionTypes.GET_DOOR_TO_DOOR_REPORTS,
})

export const getDoorToDoorReportsSuccess = ({ monthYear, majlis, result }) => ({
  payload: { monthYear, majlis, result },
  type: actionTypes.GET_DOOR_TO_DOOR_REPORTS_SUCCESS,
})

export const getAllMuhtamimReports = ({ date, viewForYear }) => ({
  payload: { date, viewForYear },
  type: actionTypes.GET_ALL_MUHTAMIM_REPORTS,
})

export const getAllMuhtamimReportsSuccess = ({ key, result }) => ({
  payload: { key, result },
  type: actionTypes.GET_ALL_MUHTAMIM_REPORTS_SUCCESS,
})

export const getIndividualNamazReports = ({ monthYear }) => ({
  payload: { monthYear },
  type: actionTypes.GET_INDIVIDUAL_NAMAZ_REPORTS,
})

export const getIndividualNamazReportsSuccess = ({ monthYear, result }) => ({
  payload: { monthYear, result },
  type: actionTypes.GET_INDIVIDUAL_NAMAZ_REPORTS_SUCCESS,
})

export const getMajlisNamazReports = ({ monthYear }) => ({
  payload: { monthYear },
  type: actionTypes.GET_MAJLIS_NAMAZ_REPORTS,
})

export const getMajlisNamazReportsSuccess = ({ monthYear, result }) => ({
  payload: { monthYear, result },
  type: actionTypes.GET_MAJLIS_NAMAZ_REPORTS_SUCCESS,
})

export const getSpiritualReformReports = ({ year }) => ({
  payload: { year },
  type: actionTypes.GET_SPIRITUAL_REFORM_REPORTS,
})
export const getSpiritualReformReportsSuccess = ({ year, result }) => ({
  payload: { year, result },
  type: actionTypes.GET_SPIRITUAL_REFORM_REPORTS_SUCCESS,
})

export const ijtemaGetSummary = () => ({
  payload: {},
  type: actionTypes.IJTEMA_GET_SUMMARY,
})

export const ijtemaGetSummarySuccess = (fullResponse) => ({
  payload: {
    ...fullResponse,
  },
  type: actionTypes.IJTEMA_GET_SUMMARY_SUCCESS,
})

export const ijtemaGetMembers = ({ majlis }) => ({
  payload: { majlis },
  type: actionTypes.IJTEMA_GET_MEMBERS,
})

export const ijtemaGetMembersSuccess = (fullResponse) => ({
  payload: {
    ...fullResponse,
  },
  type: actionTypes.IJTEMA_GET_MEMBERS_SUCCESS,
})

export const ijtemaMarkAsPresent = ({ ijtema_id, day }) => ({
  payload: { ijtema_id, day },
  type: actionTypes.IJTEMA_MARK_AS_PRESENT,
})

export const ijtemaMarkAsPresentSuccess = (fullResponse) => ({
  payload: fullResponse,
  type: actionTypes.IJTEMA_MARK_AS_PRESENT_SUCCESS,
})

export const ijtemaGetSummary2023 = () => ({
  payload: {},
  type: actionTypes.IJTEMA_GET_SUMMARY_2023,
})

export const ijtemaGetSummarySuccess2023 = (fullResponse) => ({
  payload: {
    ...fullResponse,
  },
  type: actionTypes.IJTEMA_GET_SUMMARY_SUCCESS_2023,
})

export const ijtemaGetMembers2023 = ({ majlis }) => ({
  payload: { majlis },
  type: actionTypes.IJTEMA_GET_MEMBERS_2023,
})

export const ijtemaGetMembersSuccess2023 = (fullResponse) => ({
  payload: {
    ...fullResponse,
  },
  type: actionTypes.IJTEMA_GET_MEMBERS_SUCCESS_2023,
})
